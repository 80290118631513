import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AccountCircle as AccountCircleIcon, Close as CloseIcon } from '@material-ui/icons';
import React, { memo } from 'react';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '10px'
  },
  icon: {
    display: 'flex',
    width: '40px',
    height: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.29)'
  },
  name: {
    marginLeft: '16px',
    flex: 1
  }
});

const GroupUserDetails = ({
  avatarUrl,
  firstName,
  lastName,
  isTheSameUser,
  shouldShowRemoveButton,
  handleRemoveFromTeam
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {avatarUrl ? (
        <img className={classes.icon} src={avatarUrl} alt="User avatar" />
      ) : (
        <AccountCircleIcon className={classes.icon} />
      )}
      <div className={classes.name}>
        {`${firstName} ${lastName}${isTheSameUser ? ' (you)' : ''}`}
      </div>
      {shouldShowRemoveButton && (
        <Button onClick={handleRemoveFromTeam}>
          <CloseIcon />
        </Button>
      )}
    </div>
  );
};

export default memo(GroupUserDetails);
