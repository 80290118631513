import { defineMessages } from 'react-intl';

export const scope = 'app.components.TipDirect.GroupCardDetails';

export default defineMessages({
  team: {
    id: `${scope}.team`,
    defaultMessage: 'Team'
  },
  resources: {
    id: `${scope}.resources`,
    defaultMessage: 'Resources'
  },
  members: {
    id: `${scope}.members`,
    defaultMessage: 'Members'
  },
  subText: {
    id: `${scope}.subText`,
    defaultMessage: 'Currently in this team'
  },
  noTeam: {
    id: `${scope}.noTeam`,
    defaultMessage: 'Not currently in a team'
  },
  removeResourceConfirmation: {
    id: `${scope}.removeResourceConfirmation`,
    defaultMessage: 'Confirm you wish to remove this resource from the team'
  },
  removeMemberConfirmation: {
    id: `${scope}.removeMemberConfirmation`,
    defaultMessage: 'Confirm you wish to remove this member from the team'
  }
});
