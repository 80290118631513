import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Dashboard';

export default defineMessages({
  copiedToClipboard: {
    id: `${scope}.copied_to_clipboard`,
    defaultMessage: 'Copied to clipboard'
  },
  viewPayments: {
    id: `${scope}.view_payments`,
    defaultMessage: 'View Payments'
  },
  firstTimeUserMessage: {
    id: `${scope}.first_time_user`,
    defaultMessage:
      "Once you start making tips, we'll display a breakdown of what you have earned here."
  },
  averageRating: {
    id: `${scope}.average_rating`,
    defaultMessage: 'Average Rating'
  },
  recentPayments: {
    id: `${scope}.recent_payments`,
    defaultMessage: 'Recent Payments'
  },
  lastSevenDays: {
    id: `${scope}.last_seven_days`,
    defaultMessage: 'Last 7 days'
  },
  totalTips: {
    id: `${scope}.total_tips`,
    defaultMessage: 'Total tips'
  },
  totalAllTime: {
    id: `${scope}.total_all_time`,
    defaultMessage: 'Total[All Time]'
  }
});
