import { Analytics } from '@analytics';
import { userHasRevolut } from '@api/payout';
import { connectApplePay } from '@api/taptotip';
import StripeLogo from '@assets/images/Stripe.jpg';
import CheckMark from '@assets/images/booking_widget/checkMarkGreen.svg';
import RevolutGradient from '@assets/images/payment/RevolutGradient.jpg';
import SafeAndSecure from '@assets/images/payment/SafeAndSecure.svg';
import WarningIcon from '@assets/images/warning.svg';
import TooltipWrapper from '@components/Common/TooltipWrapper';
import CountryDropdown from '@components/CountryDropdown';
import TopNav from '@components/Nav/TopNav';
import TipDirectLeftMenu from '@components/Nav/TopNav/TipDirectLeftMenu';
import RevolutWireDialogContent from '@components/TapToTip/AddRevolutDetails';
import AddToursModal from '@components/TapToTip/AddToursModal';
import GuideTours from '@components/TapToTip/GuideTours';
import PaymentOptionsSwitcher from '@components/TapToTip/PaymentOptionsSwitcher';
import { RevolutCurrencies, TTTPaymentPlatforms } from '@constants';
import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Snackbar,
  TextField,
  Typography
} from '@material-ui/core';
import { AccountCircle as AccountIcon, ClearOutlined as CancelIcon } from '@material-ui/icons';
import { Alert as MuiAlert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { StyleBreakpoints } from '@utils/constants';
import { countries, relevantCurrencies, tipDirectCountries } from '@utils/helpers';
import { useInjectReducer } from '@utils/injectReducer';
import { useInjectSaga } from '@utils/injectSaga';
import { Field, Form, Formik } from 'formik';
import { DropzoneDialog } from 'material-ui-dropzone';
import * as R from 'ramda';
import { default as React, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as yup from 'yup';
import * as actions from './actions';
import BusinessStatus from './businessStatus';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';

const useStyles = makeStyles(theme => ({
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      flexDirection: 'row'
    }
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'minmax(auto, 395px) minmax(auto, 395px)',
    gap: '72px',
    padding: '30px 40px',
    overflowX: 'scroll',

    '&::-webkit-scrollbar': {
      display: 'none'
    },

    [theme.breakpoints.down(StyleBreakpoints.md)]: {
      gridTemplateColumns: 'minmax(auto, 100%)',
      maxWidth: '395px',
      alignSelf: 'center',
      gap: '32px',
      marginLeft: 0
    }
  },
  headerText: {
    fontSize: 24,
    color: '#000',
    fontWeight: 'bold'
  },
  details: {
    fontSize: 14,
    color: '#a6a6a6',
    fontWeight: 500
  },
  detailsText: {
    fontSize: 13,
    color: '#a6a6a6'
  },
  infoBlock: {
    marginBottom: 20,
    minWidth: 250,

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      marginRight: 0
    },
    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      display: 'grid',
      gridTemplateRows: '0fr'
    },

    '& .MuiStepper-root': {
      paddingLeft: 0
    }
  },
  profileImage: {
    width: '50%',
    maxWidth: '144px',
    marginBottom: '20px',
    marginRight: '20px',
    marginTop: '20px',
    cursor: 'pointer',
    borderRadius: '50%',

    '&:hover': {
      filter: 'brightness(80%)'
    }
  },
  textField: {
    marginBottom: '20px',
    marginTop: 8,
    width: '100%',
    minWidth: '250px',

    '& label.Mui-focused': {
      fontWeight: 600,
      color: '#6b6b6b'
    },
    '& input': {
      fontWeight: 500
    }
  },
  validationErrorMessage: {
    color: 'red'
  },
  profileDefaultImg: {
    width: '50%',
    minWidth: '250px',
    color: '#d8d8d8',
    marginTop: '20px',
    marginBottom: '20px',
    fontSize: 180,
    cursor: 'pointer',

    '&:hover': {
      filter: 'brightness(80%)'
    }
  },
  contentTitle: {
    fontWeight: '600',
    fontSize: '14px',
    color: '#4c4c4c'
  },
  paymentGatewayRow: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    marginBottom: '20px'
  },
  paymentGatewayCard: {
    borderRadius: '11.2px',
    boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: '#ffffff',
    maxWidth: '347px',
    cursor: 'pointer',
    position: 'relative'
  },
  paymentGatewayDetailsImage: {
    marginBottom: '20px',
    maxWidth: '100%',
    borderRadius: '6px 6px 0 0'
  },
  paymentGatewayDetailsText: {
    margin: '0 30px 30px 30px',
    fontSize: '12px',
    color: '#a1a2a2'
  },
  paymentGatewayGetStartedRow: {
    display: 'flex',
    paddingRight: '20px',
    flexDirection: 'row-reverse',
    color: '#767777',
    fontSize: '12px',
    marginBottom: '20px'
  },
  paymentIntegration: {
    margin: '20px 0px',
    fontSize: '12px'
  },
  statusIndicator: {
    padding: '4px 10px',
    color: 'white',
    fontSize: '11px',
    borderRadius: '4px',
    fontWeight: 'bold'
  },
  revolutInactive: {
    backgroundColor: '#dbdbdb',
    color: '#6a6b6b'
  },
  inactive: {
    backgroundColor: '#555555'
  },
  active: {
    backgroundColor: '#d9ffd7',
    color: '#12573e'
  },
  alertIcon: {
    width: '22px',
    height: '19px',
    margin: 'auto 0'
  },
  messagingRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  messagingCard: {
    marginTop: '17px',
    padding: '22px 17px'
  },
  messagingText: {
    fontSize: '11px',
    marginLeft: '11px',
    color: '#000',
    letterSpacing: '-0.17px'
  },
  saveButton: {
    backgroundColor: '#6997ff',
    color: '#fff',
    marginBottom: 16,
    width: '100%',
    padding: '8px 24px',
    '&:hover:not($disabled):not($focused):not($error)': {
      backgroundColor: '#87acff'
    }
  },
  applePayButton: {
    backgroundColor: '#6997ff',
    color: '#fff',
    float: 'right',
    '&:hover:not($disabled):not($focused):not($error)': {
      backgroundColor: '#87acff'
    }
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      justifyContent: 'flex-start'
    }
  },
  paymentTypeHeader: {
    fontSize: '14px',
    fontWeight: 500
  },
  paymentTypeLabel: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#555',
    display: 'flex',
    alignItems: 'center',
    height: '32px',

    '&:last-of-type': {
      marginBottom: '0'
    }
  },
  circle: {
    display: 'inline-block',
    marginRight: '8px',
    position: 'relative',
    width: '14px',
    height: '14px',
    borderRadius: '50%'
  },
  enabled: {
    backgroundColor: 'green'
  },
  actionRequired: {
    backgroundColor: 'orange'
  },
  paymentText: {
    flex: 1
  },
  marginAbove: {
    marginTop: '2em'
  },
  smallMarginBelow: {
    marginBottom: '2em'
  },
  marginBelow: {
    marginBottom: '2em'
  },
  paypalStatus: {
    width: '66px',
    padding: '4px 8px',
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: 'bold',
    borderRadius: '4px',
    backgroundColor: 'rgb(217, 255, 215)',
    color: 'rgb(18, 87, 62)'
  },
  paypalStatusTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#000',
    marginRight: 4
  },
  paymentGateWayHeader: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    width: 'fit-content',
    paddingRight: '13px',
    marginTop: '14px',
    justifyContent: 'flex-end',
    right: 0,
    height: '22px'
  },
  safeAndSecureMessaging: {
    width: 'auto',
    height: '26px',
    marginTop: '-1px',
    marginRight: '8px'
  },
  connectedRevolutContainer: {
    maxWidth: '347px',
    backgroundColor: '#f9f9f9',
    padding: '27px 18px 22px 18px',
    borderRadius: '16px'
  },
  revolutSyncBox: {
    width: '100%',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '14px 30px 31px 30px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    borderRadius: '5px'
  },
  revolutSyncTitle: { fontSize: '14px', fontWeight: '600', color: '#22644d', marginBottom: '7px' },
  revolutSyncText: { fontSize: '12px', fontWeight: '500', color: '2c745c' },
  revolutCheckMark: {
    width: '23px',
    height: 'auto',
    margin: '0 auto 7px auto'
  },
  wrapper: {
    display: 'flex',
    alignItems: 'centre'
  },
  cancelIcon: {
    marginLeft: 'auto',
    cursor: 'pointer'
  },
  toursRow: {
    marginBottom: '32px',
    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      order: '-1'
    }
  },
  tipPaymentContainer: {
    marginTop: '20px',
    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      order: '-2'
    }
  },
  mobileLayout: {
    display: 'flex',
    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      display: 'none'
    }
  },
  desktopLayout: {
    display: 'none',
    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      display: 'flex'
    }
  }
}));

const initialProfile = {
  firstName: '',
  lastName: '',
  description: '',
  payment: '',
  defaultPaymentAmount: 10,
  paymentOptionEnabled: 'bespoke',
  country: 'ie',
  currency: 'eur',
  businessStatus: 'individual',
  taxNumber: '',
  paymentOptions: {
    paymentOptionEnabled: 'bespoke',
    quickPaymentAmounts: {
      good: 0,
      great: 0,
      amazing: 0
    },
    defaultPaymentAmount: 0
  }
};

const profileSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  description: yup.string(),
  currency: yup.string().required(),
  country: yup.string().required()
});

const TippingProfile = ({
  profile,
  updateProfile,
  isLoading,
  uploadUserProfileImage,
  connectStripeResponse,
  activeTours,
  addToursToProfile,
  paypalLoginUrl,
  paypalAccount,
  getPayPalLoginUrl,
  user,
  fetchProfile,
  connectStripe,
  fetchActiveTours,
  paypalLoginCallback
}) => {
  const classes = useStyles();
  useInjectReducer({ key: 'taptotip', reducer });
  useInjectSaga({ key: 'taptotip', saga });
  const intl = useIntl();
  const [step, setStep] = useState(0);
  const [showAlert, setShouldShowCopyAlert] = useState(false);
  const [isDropzoneOpen, setIsDropzoneOpen] = useState(false);
  const [connectStripeResponseState, setConnectStripeResponse] = useState(connectStripeResponse);
  const mergedProfile = { ...initialProfile, ...profile };
  const [isOpen, setIsOpen] = useState(false);
  const [isEnablingApplePay, setIsEnablingApplePay] = useState(false);
  const [isApplePayEnabled, setIsApplePayEnabled] = useState(false);
  const [hideStripeAndWire, setHideStripeAndWire] = useState(false);
  const [activePaymentPlatform, setActivePaymentPlatform] = useState('');

  const [revolutRemittanceModalIsOpen, setRevolutRemittanceModalIsOpen] = useState(false);
  const [remittanceModalIsOpen, setRemittanceModalIsOpen] = useState(false);
  const isRevolutCurrencyEnabled = Boolean(profile?.revolut?.currency);
  const isPayPalEnabled = Boolean(profile?.paypal || paypalAccount);
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');

  useEffect(() => {
    if (!profile || profile.userId !== user.userId) {
      fetchProfile(user.userId);
    }

    if (!activeTours?.length) {
      fetchActiveTours();
    }

    if (user?.profile.stripeConnect?.stripeClientId) {
      connectStripe();
    }

    if (!paypalAccount && !profile?.paypal && code) {
      paypalLoginCallback(code);
    }
  }, []);

  useEffect(() => {
    const hasStripeConnect = () => {
      const stripeConnected =
        connectStripeResponse?.paymentIntegrationStatus === 'active' ||
        connectStripeResponseState?.paymentIntegrationStatus === 'active';
      stripeConnected && setActivePaymentPlatform(TTTPaymentPlatforms.stripe);
    };

    const hasRevolut = async () => {
      try {
        const revolutUser = await userHasRevolut(user.userId);
        if (revolutUser.exists) {
          setActivePaymentPlatform(TTTPaymentPlatforms.revolut);
          setHideStripeAndWire(true);
        }
      } catch (e) {
        //noop
      }
    };
    // call the function
    hasStripeConnect();
    hasRevolut();
  }, []);

  useEffect(() => {
    if (paypalLoginUrl && (!profile?.paypal || !paypalAccount)) {
      window.open(paypalLoginUrl, '_blank', 'noopener,noreferrer');
    }
  }, [paypalLoginUrl]);

  const redirectStripe = redirectUrl => {
    window.location = redirectUrl;
  };

  const handleDrop = files => {
    if (files[0]) {
      uploadUserProfileImage(files[0]);
    }
    setIsDropzoneOpen(false);
  };

  const handleRevolutRemittanceClose = () => {
    setRevolutRemittanceModalIsOpen(false);
  };

  const handleTipValueSubmit = values => {
    Analytics.track('updated suggested tip amount options');
    updateProfile({ userId: user.userId, profile: values });
  };

  const steps = [
    {
      label: intl.formatMessage(messages.imageHeader),
      fieldName: 'image'
    },
    {
      label: intl.formatMessage(messages.firstNameHeader),
      fieldName: 'firstName'
    },
    {
      label: intl.formatMessage(messages.lastNameHeader),
      fieldName: 'lastName'
    },
    {
      label: intl.formatMessage(messages.nicknameHeader),
      fieldName: 'nickname'
    },
    {
      label: intl.formatMessage(messages.bioHeader),
      fieldName: 'description'
    },
    {
      label: intl.formatMessage(messages.paymentHeader),
      fieldName: 'currency'
    },
    {
      label: intl.formatMessage(messages.countryHeader),
      fieldName: 'country'
    }
  ];

  const getStepContent = (step, values, handleChangeField) => {
    switch (step) {
      case 0:
        return (
          <Grid>
            <Grid className={classes.imageContainer}>
              {profile?.avatarUrl ? (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                <img
                  onClick={() => setIsDropzoneOpen(true)}
                  src={profile?.avatarUrl}
                  className={classes.profileImage}
                  alt="Profile Avatar"
                />
              ) : (
                <AccountIcon
                  onClick={() => setIsDropzoneOpen(true)}
                  className={classes.profileDefaultImg}
                />
              )}
            </Grid>
            <DropzoneDialog
              cancelButtonText={intl.formatMessage(messages.imageCancel)}
              dialogTitle={
                <div className={classes.wrapper}>
                  <span>{intl.formatMessage(messages.imageHeader)}</span>
                  <CancelIcon
                    onClick={() => setIsDropzoneOpen(false)}
                    className={classes.cancelIcon}
                  />
                </div>
              }
              submitButtonText={intl.formatMessage(messages.imageSubmit)}
              open={isDropzoneOpen}
              onSave={handleDrop}
              maxFileSize={5000000}
              filesLimit={1}
              onClose={() => setIsDropzoneOpen(false)}
            />
          </Grid>
        );
      case 1:
        return (
          <TextField
            className={classes.textField}
            label={intl.formatMessage(messages.firstNameLabel)}
            variant="outlined"
            value={values.firstName}
            onChange={e => handleChangeField(e.target.value)}
          />
        );
      case 2:
        return (
          <TextField
            className={classes.textField}
            label={intl.formatMessage(messages.lastNameLabel)}
            variant="outlined"
            value={values.lastName}
            onChange={e => handleChangeField(e.target.value)}
          />
        );
      case 3:
        return (
          <TooltipWrapper
            shouldShowTooltip={true}
            title={intl.formatMessage(messages.nicknameToolTip)}>
            <TextField
              className={classes.textField}
              label={intl.formatMessage(messages.nicknameLabel)}
              variant="outlined"
              value={values.nickname}
              inputProps={{
                maxLength: 20
              }}
              onChange={e => handleChangeField(e.target.value)}
            />
          </TooltipWrapper>
        );
      case 4:
        return (
          <TextField
            multiline
            minRows={4}
            className={classes.textField}
            label={intl.formatMessage(messages.bioLabel)}
            InputLabelProps={{ shrink: true }}
            placeholder={intl.formatMessage(messages.bioPlaceholder)}
            variant="outlined"
            value={values.description}
            onChange={e => {
              handleChangeField(e.target.value);
            }}
            inputProps={{ maxLength: 200 }}
          />
        );
      case 5:
        return (
          <TooltipWrapper
            shouldShowTooltip={isRevolutCurrencyEnabled}
            title={intl.formatMessage(messages.currencyTooltip)}>
            <TextField
              id="standard-select-currency"
              disabled={isRevolutCurrencyEnabled}
              select
              label={intl.formatMessage(messages.paymentLabel)}
              variant="outlined"
              className={classes.textField}
              value={profile?.revolut?.currency || values.currency}
              onChange={e => handleChangeField(e.target.value)}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }
              }}>
              {Object.entries({
                ...relevantCurrencies,
                ...(isRevolutCurrencyEnabled && { ...RevolutCurrencies })
              }).map(([key, symbol]) => (
                <MenuItem key={key} value={key}>
                  {symbol}
                </MenuItem>
              ))}
            </TextField>
          </TooltipWrapper>
        );
      case 6:
        return (
          <CountryDropdown
            id="standard-select-country"
            updateCountry={(_, value) => handleChangeField(value)}
            availableCountries={tipDirectCountries}
            allCountries={countries}
            value={values.country ?? ''}
            label={intl.formatMessage(messages.countryLabel)}
            className={classes.textField}
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                getContentAnchorEl: null
              }
            }}
          />
        );
      default:
        'Unknown step';
    }
  };

  const shouldDisplayPaymentPlatform = paymentPlatform => {
    return 'none';
    if (paymentPlatform === activePaymentPlatform || !activePaymentPlatform) {
      return 'block';
    }
    return 'none';
  };

  const validateSchema = schema => values =>
    schema
      .validate(values, {
        abortEarly: false,
        strict: false
      })
      .then(() => ({}))
      .catch(({ inner }) => {
        // if got errors then focus on the first step with error and return all errors
        if (inner.length) {
          // find step by fieldName from the error path
          window.scrollTo(0, 0);
          setStep(steps.findIndex(step => step.fieldName === inner[0].path));
        }

        return inner.reduce(
          (errorInfo, { path, message }) => ({
            ...errorInfo,
            [path]: (errorInfo[path] || []).concat(message)
          }),
          {}
        );
      });

  const handleFormSubmit = values => {
    Analytics.track('save profile', {}, true);
    if (
      values.country &&
      values.firstName &&
      values.lastName &&
      values.description &&
      values.currency
    ) {
      Analytics.moengageAddUserAttribute({
        hasCompletedProfile: true
      });
    }

    updateProfile({ userId: user.userId, profile: values });
  };

  const handleBusinessStatusFormSubmit = values => {
    Analytics.track('update business status', {
      businessStatus: values.businessStatus,
      isTaxNumberSet: !!values.taxNumber
    });

    Analytics.moengageAddUserAttribute({
      businessStatus: values.businessStatus
    });

    updateProfile({ userId: user.userId, profile: values });
  };

  const handleLoginWithPayPal = async () => {
    getPayPalLoginUrl();
  };

  return (
    <>
      <TopNav />
      <div className={classes.outerContainer}>
        <TipDirectLeftMenu selectedTab={'Profile'} />
        {isLoading && (
          <Grid className={classes.container}>
            <CircularProgress />
          </Grid>
        )}
        {!isLoading && (
          <Grid className={classes.container}>
            <Grid className={classes.infoBlock}>
              <div className={classes.headerText}>{intl.formatMessage(messages.profile)}</div>
              <div className={classes.details}>{intl.formatMessage(messages.details)}</div>
              <Formik
                initialValues={mergedProfile}
                validateOnChange={false}
                validate={validateSchema(profileSchema)}
                validateOnBlur={false}
                onSubmit={handleFormSubmit}>
                {({ errors, touched, values }) => (
                  <Form>
                    {steps.map(({ label, fieldName }, index) => (
                      <div key={label}>
                        <Typography>
                          <Field name={fieldName}>
                            {({ form }) => {
                              return getStepContent(index, values, val => {
                                form.setFieldValue(fieldName, val);
                              });
                            }}
                          </Field>
                          {errors[fieldName] && touched[fieldName] && (
                            <div className={classes.validationErrorMessage}>
                              {errors[fieldName]}
                            </div>
                          )}
                        </Typography>
                      </div>
                    ))}
                    <Grid item xs={12} className={classes.buttonContainer}>
                      <Button type="submit" className={classes.saveButton}>
                        {intl.formatMessage(messages.saveChanges)}
                      </Button>
                    </Grid>
                  </Form>
                )}
              </Formik>
              <div className={classes.desktopLayout}>
                <BusinessStatus
                  mergedProfile={mergedProfile}
                  updateProfile={profile => updateProfile({ userId: user.userId, profile })}
                  handleSubmit={handleBusinessStatusFormSubmit}
                  validateSchema={validateSchema}
                />
              </div>
              <div className={`${classes.mobileLayout} ${classes.tipPaymentContainer}`}>
                <PaymentOptionsSwitcher
                  mergedProfile={mergedProfile}
                  validateSchema={validateSchema}
                  handleSubmit={handleTipValueSubmit}
                  asForm={true}
                />
              </div>
            </Grid>
            <Grid className={classes.infoBlock}>
              <div className={classes.toursRow}>
                <GuideTours
                  tours={profile?.tours}
                  setIsOpen={isOpen => {
                    Analytics.track('profile select tours');
                    setIsOpen(isOpen);
                  }}
                />
              </div>
              <div className={classes.mobileLayout}>
                <BusinessStatus
                  mergedProfile={mergedProfile}
                  updateProfile={profile => updateProfile({ userId: user.userId, profile })}
                  handleSubmit={handleBusinessStatusFormSubmit}
                  validateSchema={validateSchema}
                />
              </div>
              <div className={`${classes.desktopLayout} ${classes.tipPaymentContainer}`}>
                <PaymentOptionsSwitcher
                  mergedProfile={mergedProfile}
                  validateSchema={validateSchema}
                  handleSubmit={handleTipValueSubmit}
                  asForm={true}
                />
              </div>

              <div className={classes.paymentIntegration} style={{ display: 'none' }}>
                {intl.formatMessage(messages.paymentIntegrationStatus)}:{' '}
                {connectStripeResponseState?.paymentIntegrationStatus === 'active' ||
                connectStripeResponse?.paymentIntegrationStatus === 'active' ? (
                  <span className={`${classes.statusIndicator} ${classes.active}`}>
                    {intl.formatMessage(messages.active)}
                  </span>
                ) : (
                  <span className={`${classes.statusIndicator} ${classes.inactive}`}>
                    {intl.formatMessage(messages.inactive)}
                  </span>
                )}
              </div>
              <div className={classes.paymentGatewayRow}>
                <div
                  className={classes.paymentGatewayCard}
                  role="button"
                  tabIndex="0"
                  style={{
                    display: shouldDisplayPaymentPlatform(TTTPaymentPlatforms.stripe)
                  }}
                  onKeyDown={redirectStripe}
                  onClick={async () => {
                    setShouldShowCopyAlert(true);
                    const response = await connectStripe();
                    setConnectStripeResponse(response);
                    setShouldShowCopyAlert(false);

                    redirectStripe(response.redirectUrl);
                  }}>
                  <img
                    src={StripeLogo}
                    alt="stripe connect"
                    className={classes.paymentGatewayDetailsImage}
                  />
                  <div className={classes.paymentGatewayDetailsText}>
                    <div className={classes.contentTitle}>
                      {intl.formatMessage(messages.stripeConnect)}
                    </div>
                    {intl.formatMessage(messages.stripeConnectDescription)}
                  </div>
                  <div className={classes.paymentGatewayGetStartedRow}>
                    {intl.formatMessage(messages.getStarted)}
                  </div>
                </div>
                <div
                  style={{
                    display: shouldDisplayPaymentPlatform(TTTPaymentPlatforms.stripe)
                  }}
                  className={`${classes.paymentGatewayCard} ${classes.messagingCard}`}>
                  <div className={classes.messagingRow}>
                    <img className={classes.alertIcon} alt="" src={WarningIcon} />
                    <div className={classes.messagingText}>
                      {intl.formatMessage(messages.stripeConnectMessaging)}
                    </div>
                  </div>
                </div>
                {(connectStripeResponseState?.paymentIntegrationStatus === 'active' ||
                  connectStripeResponse?.paymentIntegrationStatus === 'active') && (
                  <div className={`${classes.paymentGatewayCard} ${classes.messagingCard}`}>
                    <div className={classes.paymentTypeHeader}>Payment types</div>
                    <div className={classes.paymentTypeLabel}>
                      <div className={`${classes.circle} ${classes.enabled}`} />
                      <div className={classes.paymentText}>Card</div>
                    </div>
                    <div className={classes.paymentTypeLabel}>
                      <div className={`${classes.circle} ${classes.enabled}`} />
                      <div className={classes.paymentText}>Google Pay</div>
                    </div>
                    <div className={classes.paymentTypeLabel}>
                      <div
                        className={`
                    ${classes.circle} ${
                          isApplePayEnabled || profile?.stripeConnect?.isApplePayEnabled
                            ? classes.enabled
                            : classes.actionRequired
                        }`}
                      />
                      <div className={classes.paymentText}>Apple Pay</div>
                      {!isApplePayEnabled && !profile?.stripeConnect?.isApplePayEnabled && (
                        <Button
                          onClick={async () => {
                            setIsEnablingApplePay(true);
                            await connectApplePay();
                            setIsApplePayEnabled(true);
                            setIsEnablingApplePay(false);
                          }}
                          className={classes.applePayButton}>
                          {isEnablingApplePay ? 'Loading' : 'Enable'}
                        </Button>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div
                className={[classes.details, classes.marginAbove, classes.smallMarginBelow].join(
                  ' '
                )}
                style={{
                  display: shouldDisplayPaymentPlatform(TTTPaymentPlatforms.revolut)
                }}>
                {intl.formatMessage(messages.getPaidWithRevolut)}
              </div>
              <div className={classes.paymentGatewayRow}>
                <div
                  className={classes.paymentGatewayCard}
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    setRevolutRemittanceModalIsOpen(true);
                  }}
                  style={{
                    display: shouldDisplayPaymentPlatform(TTTPaymentPlatforms.revolut)
                  }}>
                  <div className={classes.paymentGateWayHeader}>
                    <img
                      className={classes.safeAndSecureMessaging}
                      src={SafeAndSecure}
                      alt="Cvc icon"
                    />
                    {activePaymentPlatform ? (
                      <div className={`${classes.statusIndicator} ${classes.active}`}>
                        {intl.formatMessage(messages.active)}
                      </div>
                    ) : (
                      <div className={`${classes.statusIndicator} ${classes.revolutInactive}`}>
                        {intl.formatMessage(messages.notActive)}
                      </div>
                    )}
                  </div>
                  <img
                    src={RevolutGradient}
                    alt="stripe connect"
                    className={classes.paymentGatewayDetailsImage}
                  />
                  <div className={classes.paymentGatewayDetailsText}>
                    <div className={classes.contentTitle}>
                      {intl.formatMessage(messages.revolut)}
                    </div>
                    {intl.formatMessage(messages.addRevolutDetails)}
                  </div>
                  <div className={classes.paymentGatewayGetStartedRow}>
                    {intl.formatMessage(messages.getStarted)}
                  </div>
                </div>
                <div className={classes.marginBelow}>
                  <RevolutWireDialogContent
                    userId={user.userId}
                    isOpen={revolutRemittanceModalIsOpen}
                    onClose={handleRevolutRemittanceClose}
                  />
                </div>
                {activePaymentPlatform === TTTPaymentPlatforms.revolut && (
                  <div className={classes.marginBelow}>
                    <div className={classes.connectedRevolutContainer}>
                      <div className={classes.revolutSyncBox}>
                        <img className={classes.revolutCheckMark} src={CheckMark} />
                        <div className={classes.revolutSyncTitle}>
                          {intl.formatMessage(messages.revolutSynced)}
                        </div>
                        <div className={classes.revolutSyncText}>
                          {intl.formatMessage(messages.selectRevolut)}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {paypalAccount || profile?.paypal ? (
                <div style={{ display: 'none' }}>
                  <span className={classes.paypalStatusTitle}>PayPal status:</span>
                  <span className={classes.paypalStatus}>
                    {paypalAccount?.status || profile?.paypal.status}
                  </span>
                </div>
              ) : (
                <>
                  <div style={{ display: 'none' }}>
                    <Button variant="outlined" color="primary" onClick={handleLoginWithPayPal}>
                      Log in with PayPal
                    </Button>
                  </div>
                </>
              )}
            </Grid>
          </Grid>
        )}
      </div>
      <Snackbar
        open={showAlert}
        onClose={() => {
          setShouldShowCopyAlert(false);
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <MuiAlert
          variant="filled"
          elevation={6}
          severity="info"
          onClose={() => {
            setShouldShowCopyAlert(false);
          }}>
          {intl.formatMessage(messages.connecting)}
        </MuiAlert>
      </Snackbar>

      <AddToursModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        activeTours={activeTours}
        profile={profile}
        addToursToProfile={tourIds => addToursToProfile(user.userId, tourIds)}
        userId={user.userId}
      />
    </>
  );
};

const mapStateToProps = state => ({
  profile: state.taptotip?.profile,
  isLoading: state.taptotip?.isLoading,
  connectStripeResponse: state.taptotip?.connectStripeResponse,
  activeTours: state.taptotip?.activeTours,
  user: state.user?.user,
  tours: state.taptotip?.tours,
  paypalLoginUrl: state.taptotip?.paypalLoginUrl,
  paypalAccount: state.taptotip?.paypalAccount
});

const mapDispatchToProps = dispatch => ({
  fetchProfile: userId => dispatch(actions.fetchProfile(userId)),
  updateProfile: R.compose(
    dispatch,
    actions.updateProfile
  ),
  uploadUserProfileImage: image => dispatch(actions.uploadUserProfileImage(image)),
  connectStripe: () => dispatch(actions.connectStripe()),
  fetchActiveTours: () => dispatch(actions.fetchActiveTours()),
  addToursToProfile: (userId, tourIds) => dispatch(actions.addToursToProfile({ userId, tourIds })),
  getPayPalLoginUrl: () => dispatch(actions.getPayPalLoginUrl()),
  paypalLoginCallback: code => dispatch(actions.paypalLoginCallback(code))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TippingProfile);
