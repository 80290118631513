import { Paper, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { StyleBreakpoints, UserRoles } from '@utils/constants';
import { formatMoney, formatMoneyAndCurrencySymbol } from '@utils/helpers';
import { useInjectReducer } from '@utils/injectReducer';
import { useInjectSaga } from '@utils/injectSaga';
import { format } from 'date-fns';
import { CsvBuilder } from 'filefy';
import MaterialTable from 'material-table';
import { default as React, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import TopNav from '../../../components/Nav/TopNav';
import TipDirectLeftMenu from '../../../components/Nav/TopNav/TipDirectLeftMenu';
import TextIconInformationBox from '../../../components/Onboarding/TextIconInformationBox';
import { TextIconBoxType } from '../../../components/Onboarding/constants';
import localizationMessages from '../../../components/Resources/ResourcesTable/messages';
import * as actions from './actions';
import {
  TransactionStatusBackgroundColor,
  TransactionStatusForeColor,
  TransactionStatusType
} from './constants';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';

const useStyles = makeStyles(theme => ({
  striped: {
    '& .MuiTableRow-root': {
      '&:nth-child(even)': {
        backgroundColor: '#FAF7FA'
      }
    }
  },
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      flexDirection: 'row'
    }
  },
  container: {
    padding: '24px',
    display: 'flex',
    justifyContent: 'flex-start',
    width: '80%',
    flexDirection: 'column',

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      width: '100%',
      padding: 'unset'
    }
  },
  table: {
    width: '100%'
  },
  status: {
    padding: '4px 4px',
    fontSize: '11px',
    textAlign: 'center',
    fontWeight: 'bold',
    width: '66px',
    borderRadius: '4px'
  },
  headerContainer: {
    padding: '20px',
    width: '100%'
  },
  groupContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: '16px',
    maxWidth: '418px',

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      flex: 1,
      width: '100%',
      maxWidth: 'unset'
    }
  },
  containerBackground: {
    borderRadius: '16px',
    backgroundColor: '#f9f9f9',
    padding: '24px 16px',
    width: '100%'
  },
  wrapper: {
    width: '100%',
    padding: '20px 16px',
    backgroundColor: '#f9f9f9',
    borderRadius: '16px',
    height: 'fit-content',
    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      backgroundColor: 'unset'
    }
  }
}));

const TransactionStatus = ({ status, classes }) => {
  return (
    <div
      className={classes.status}
      style={{
        backgroundColor: TransactionStatusBackgroundColor[status],
        color: TransactionStatusForeColor[status]
      }}>
      {TransactionStatusType[status]}
    </div>
  );
};

const TippingPayments = ({ transactions, fetchTransactions, user, isLoading }) => {
  const intl = useIntl();
  const classes = useStyles();
  const isMobile = useMediaQuery(`(max-width:${StyleBreakpoints.sm}px)`);

  useInjectReducer({ key: 'tipPayments', reducer });
  useInjectSaga({ key: 'tipPayments', saga });

  useEffect(() => {
    if (!transactions?.length) {
      fetchTransactions(null);
    }
  }, []);

  const columns = [
    {
      field: 'status',
      title: intl.formatMessage(messages.status),
      sorting: false,
      render: rowData => <TransactionStatus status={rowData.status} classes={classes} />
    },
    {
      field: 'currency',
      title: 'Currency',
      hidden: true
    },
    {
      field: 'amount',
      title: isMobile ? 'Amount' : intl.formatMessage(messages.revenue),
      cellStyle: {
        fontWeight: 'bold',
        fontSize: isMobile ? '12px' : 'inherit'
      },
      render: rowData =>
        formatMoneyAndCurrencySymbol({ amount: rowData.amount, currency: rowData.currency })
    },
    {
      field: 'date',
      title: isMobile ? 'Date' : intl.formatMessage(messages.activityDate),
      cellStyle: {
        fontWeight: 'bold',
        fontSize: isMobile ? '12px' : 'inherit',
        color: '#b0b0b0'
      },
      render: rowData => format(new Date(rowData.date), 'dd-MM-yyyy')
    },
    {
      field: 'time',
      title: intl.formatMessage(messages.time),
      sorting: false,
      render: rowData =>
        new Date(rowData.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    },
    {
      field: 'name',
      title: intl.formatMessage(messages.name),
      render: rowData => `${rowData.firstName} ${rowData.lastName}`,
      customFilterAndSearch: (term, rowData) => {
        return (
          `${rowData.firstName.toLowerCase()} ${rowData.lastName.toLowerCase()}`.indexOf(
            term.toLowerCase()
          ) != -1
        );
      },
      hidden:
        user.role !== UserRoles.admin ||
        user.companyDetails?.features?.disableCompanyTransactionReporting
    },
    {
      field: 'userId',
      title: intl.formatMessage(messages.userId),
      hidden:
        user.role !== UserRoles.admin ||
        user.companyDetails?.features?.disableCompanyTransactionReporting
    },
    {
      field: 'transactionId',
      title: intl.formatMessage(messages.reference)
    }
  ];

  const handleExportCsv = (_, data) => {
    const columnTitles = columns.map(columnDef => columnDef.title);

    const csvData = data.map(rowData =>
      columns.map(columnDef => {
        switch (columnDef.field) {
          case 'status':
            return intl.formatMessage(messages[rowData.status || 'success']);
          case 'currency':
            return rowData.currency;
          case 'amount':
            return formatMoney({
              amount: rowData.amount,
              currency: rowData.currency
            });
          case 'date':
            return format(new Date(rowData.date), 'dd-MM-yyyy');
          case 'time':
            return new Date(rowData.date).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit'
            });
          case 'name':
            return `${rowData.firstName} ${rowData.lastName}`;
          default:
            return rowData[columnDef.field] || '';
        }
      })
    );

    const builder = new CsvBuilder('taptotip-payments.csv')
      .setDelimeter(',')
      .setColumns(columnTitles)
      .addRows(csvData)
      .exportFile();

    return builder;
  };

  // Filter out columns for mobile
  const displayedColumns = isMobile ? columns.filter((_, index) => index < 5) : columns;

  return (
    <>
      <TopNav />
      <div className={classes.outerContainer}>
        <TipDirectLeftMenu selectedTab={'Payments'} />
        <div className={classes.container}>
          {!transactions?.length && (
            <div className={`${classes.groupContainer} ${classes.containerBackground}`}>
              <TextIconInformationBox card={TextIconBoxType.NoTipsYet} whiteBackground />
            </div>
          )}
          <div className={classes.wrapper}>
            <MaterialTable
              isLoading={isLoading}
              style={{ width: '100%', borderRadius: '16px' }}
              columns={displayedColumns}
              data={transactions}
              title={intl.formatMessage(messages.payments)}
              components={{
                Container: props => <Paper className={classes.striped} {...props} />
              }}
              options={{
                exportButton: true,
                exportCsv: handleExportCsv,
                tableHeadStyle: {
                  whiteSpace: 'nowrap'
                },
                headerStyle: {
                  whiteSpace: 'nowrap',
                  position: 'sticky',
                  top: '0'
                },
                draggable: false,
                cellStyle: {
                  whiteSpace: 'nowrap',
                  width: 'fit-content',
                  fontSize: '12px'
                },
                pageSizeOptions: [5, 10, 20, 30],
                pageSize: 30,
                emptyRowsWhenPaging: false,
                maxBodyHeight: window.innerWidth > StyleBreakpoints.sm ? '75vh' : '55vh'
              }}
              localization={{
                pagination: {
                  labelRowsSelect: intl.formatMessage(localizationMessages.labelRowsSelect),
                  labelRowsPerPage: intl.formatMessage(localizationMessages.labelRowsPerPage),
                  firstAriaLabel: intl.formatMessage(localizationMessages.firstAriaLabel),
                  firstTooltip: intl.formatMessage(localizationMessages.firstTooltip),
                  previousAriaLabel: intl.formatMessage(localizationMessages.previousAriaLabel),
                  previousTooltip: intl.formatMessage(localizationMessages.previousTooltip),
                  nextAriaLabel: intl.formatMessage(localizationMessages.nextAriaLabel),
                  nextTooltip: intl.formatMessage(localizationMessages.nextTooltip),
                  lastAriaLabel: intl.formatMessage(localizationMessages.lastAriaLabel),
                  lastTooltip: intl.formatMessage(localizationMessages.lastTooltip)
                },
                toolbar: {
                  searchTooltip: intl.formatMessage(localizationMessages.searchTooltip),
                  searchPlaceholder: intl.formatMessage(localizationMessages.searchPlaceholder)
                },
                body: {
                  emptyDataSourceMessage: intl.formatMessage(
                    localizationMessages.emptyDataSourceMessage
                  )
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  transactions: state.tipPayments?.transactions,
  isLoading: state.tipPayments?.isLoading,
  user: state.user?.user
});

const mapDispatchToProps = dispatch => ({
  fetchTransactions: userId => dispatch(actions.fetchTransactions({ userId }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TippingPayments);
