import { Analytics } from '@analytics';
import BookingsIcon from '@assets/images/Bookings.svg';
import CloseIcon from '@assets/images/Close.svg';
import FilterIcon from '@assets/images/Filter.svg';
import ManifestIcon from '@assets/images/Manifest.svg';
import MobileSettingsIcon from '@assets/images/MobileSettings.svg';
import DashboardIcon from '@assets/images/menu_icons/Dashboard.svg';
import PaymentsIcon from '@assets/images/menu_icons/Payments.svg';
import ProfileIcon from '@assets/images/menu_icons/Profile.svg';
import ResourcesIcon from '@assets/images/menu_icons/Resources.svg';
import { Button, Grid, Modal, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Menu as MenuIcon } from '@material-ui/icons';
import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
// import UsersIcon from '@assets/images/menu_icons/Groups.svg';
import TripAdmitFooterMobileIcon from '@assets/images/TripAdmitFooterMobile.svg';
import UserNameIcon from '@assets/images/UserName.svg';
import ReviewsIcon from '@assets/images/menu_icons/Reviews.svg';
import TipDirectIcon from '@assets/images/menu_icons/TipDirect.svg';
import { SessionStorageKeys, UserRoles } from '@constants';
import { TapToTipTabs } from '../../../../containers/TapToTipPage';
// import UsersIcon from '../../../../assets/images/menu_icons/Groups.svg';
import TipDirectLogoIcon from '../../../../assets/images/tip_direct/TA_Icon.png';
import { isProductEnabled } from '../../../../utils/helpers';
import InviteGuideModal from '../../../TapToTip/InviteGuideModal';
import NavLink from '../NavLink';
import messages from '../messages';
import { MenuItem } from './menu-item';

const useStyles = makeStyles(theme => ({
  mobileHeader: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    width: '100%',
    gridTemplateRows: '1fr'
  },
  logo: {
    justifySelf: 'center'
  },
  filterAndMenu: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'center',
    justifySelf: 'flex-end'
  },
  modalBody: {
    position: 'absolute',
    display: 'grid',
    gridTemplateRows: '1fr 6fr 0fr 1.5fr 0fr 0.8fr',
    gridTemplateColumns: '1fr',
    backgroundColor: '#fff',
    width: '70vw',
    minWidth: '250px',
    boxShadow: 'rgb(0 0 0 / 36%) -2px 5px 8px 0px',
    borderRadius: 10,
    top: '2vw',
    right: '2vw',
    bottom: '2vw'
  },
  menuIcon: {
    fontSize: 32
  },
  topModalBlock: {
    display: 'grid',
    gridTemplateColumns: '80% 20%',
    gridTemplateRows: '1fr 10%',
    padding: '16px 16px 0 16px'
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 8px 14px 8px'
  },
  username: {
    fontWeight: 'bold',
    color: '#000',
    fontSize: 16
  },
  horizontalLine: {
    width: '100%',
    borderBottom: '1px solid #f4f4f4'
  },
  closeBtn: {
    justifySelf: 'flex-end',
    alignSelf: 'center',
    cursor: 'pointer'
  },
  menuItemsContainer: {
    padding: '0 16px 0 16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden'
  },
  menuItems: {
    width: '100%',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  signOut: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    color: '#6997ff',
    flexDirection: 'column',
    padding: '12px 16px 4px',
    cursor: 'pointer'
  },
  footerLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '8px',
    paddingTop: '8px'
  },
  avatarUrl: {
    width: '56px',
    height: '56px',
    objectFit: 'cover',
    objectPosition: '50% 50%',
    borderRadius: '50%'
  },
  userDetails: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '24px'
  },
  companyName: {
    fontWeight: 500,
    color: '#000',
    fontSize: 12
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%',
    borderBottom: 'solid 1px #ececec',
    paddingTop: '16px',
    paddingBottom: '12px'
  },
  title: {
    marginLeft: 24,
    color: 'black',
    fontWeight: 'bold'
  },
  menuItemImg: {
    width: '56px',
    height: '56px'
  },
  bottomBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 12
  },
  recommendButton: {
    borderRadius: '20px',
    color: '#acacac',
    border: '2px solid #acacac',
    padding: '8px 16px',
    fontSize: '12px',
    width: 'fit-content'
  },
  tipDirectLogo: {
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.24)',
    width: '36px',
    height: '36px',
    borderRadius: '50%'
  }
}));

export function MobileNavBar({
  username,
  user,
  withFilters = false,
  setIsOpenFilters,
  sendInvitesToGuides = null
}) {
  const classes = useStyles();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const products = user.companyDetails.products;
  const [isRecommendModalOpen, setIsRecommendModalOpen] = useState(false);
  const intl = useIntl();

  const toggleModal = () => {
    setIsModalOpen(prevState => !prevState);
  };

  const handleSignOut = () => {
    fetch('/api/v1/user/logout', { method: 'POST' })
      .then(resp => resp.json())
      .then(function() {
        Analytics.logoutOfTracking();
        window.location.href = '/signin';
      });
    sessionStorage.removeItem(SessionStorageKeys.SkipAllSteps);
  };

  const handleTapToTipTabClick = tab => {
    history.push({ pathname: `/tipdirect/${tab.toLowerCase()}`, state: { tab } });
    setIsModalOpen(false);
  };

  const tippingTabs = [
    {
      title: `${intl.formatMessage(messages.dashboard)}`,
      handleClick: () => handleTapToTipTabClick(TapToTipTabs.DASHBOARD),
      icon: DashboardIcon
    },
    {
      title: `${intl.formatMessage(messages.profile)}`,
      handleClick: () => handleTapToTipTabClick(TapToTipTabs.PROFILE),
      icon: ProfileIcon
    },
    {
      title: `${intl.formatMessage(messages.payments)}`,
      handleClick: () => handleTapToTipTabClick(TapToTipTabs.PAYMENTS),
      icon: PaymentsIcon
    },
    {
      title: `${intl.formatMessage(messages.reviews)}`,
      handleClick: () => handleTapToTipTabClick(TapToTipTabs.REVIEWS),
      icon: ReviewsIcon
    },
    {
      title: `${intl.formatMessage(messages.tipdirect)}`,
      handleClick: () => handleTapToTipTabClick(TapToTipTabs.TAPTOTIP),
      icon: TipDirectIcon
    },
    {
      title: `${intl.formatMessage(messages.resources)}`,
      handleClick: () => handleTapToTipTabClick(TapToTipTabs.RESOURCES),
      icon: ResourcesIcon
    }
  ];

  return (
    <Toolbar className={classes.mobileHeader}>
      <div className={classes.notifications}>{/* Notifications Icon will be here */}</div>
      <div className={classes.logo}>
        <NavLink link="/">
          <img
            className={classes.tipDirectLogo}
            src={TipDirectLogoIcon}
            alt="Tip Direct Mobile Logo"
          />
        </NavLink>
      </div>
      <div className={classes.filterAndMenu}>
        {withFilters && (
          <div aria-hidden onClick={setIsOpenFilters}>
            <img src={FilterIcon} alt="Filter Icon" />
          </div>
        )}
        <div aria-hidden style={{ gridArea: '1 / 2 / 1 / 2' }} onClick={toggleModal}>
          <MenuIcon color={'primary'} className={classes.menuIcon} />
        </div>
      </div>
      <Modal open={isModalOpen} onClose={toggleModal}>
        <Fragment>
          <Grid className={classes.modalBody}>
            <div className={classes.topModalBlock}>
              <div className={classes.userInfo}>
                <img
                  className={classes.avatarUrl}
                  src={user.profile?.avatarUrl || UserNameIcon}
                  alt="UserName Icon"
                />
                <div className={classes.userDetails}>
                  <span className={classes.username}>{username}</span>
                  <span className={classes.companyName}>{user.companyName || ''}</span>
                </div>
              </div>
              <img
                aria-hidden
                className={classes.closeBtn}
                onClick={toggleModal}
                src={CloseIcon}
                alt="Close Icon"
              />
              <div style={{ gridArea: '2 / 1 / 2 / 3' }} className={classes.horizontalLine} />
            </div>

            <div className={classes.menuItemsContainer}>
              <div className={classes.menuItems}>
                {isProductEnabled('TicketingSystem', products) &&
                  user.role !== UserRoles.TapToTipAgent && (
                    <>
                      <MenuItem title="Manifest" imgSrc={ManifestIcon} link="/manifest" />
                      <MenuItem title="Bookings" imgSrc={BookingsIcon} link="/bookings" />
                    </>
                  )}
                {isProductEnabled('TapToTip', products) &&
                  tippingTabs.map(tab => {
                    return (
                      <div
                        className={classes.menuItem}
                        key={tab.title}
                        title={tab.title}
                        onClick={tab.handleClick}>
                        <Button>
                          <img className={classes.menuItemImg} src={tab.icon} alt={tab.title} />
                          <span className={classes.title}>{tab.title}</span>
                        </Button>
                      </div>
                    );
                  })}
                <MenuItem title="Settings" imgSrc={MobileSettingsIcon} link="/settings" />
                {/* <MenuItem title="Tip Direct" imgSrc={BookingsIcon} link="/tipdirect" /> */}
              </div>
            </div>
            <div className={classes.horizontalLine} />
            <div className={classes.bottomBlock}>
              {sendInvitesToGuides && (
                <Button
                  className={classes.recommendButton}
                  onClick={() => setIsRecommendModalOpen(true)}>
                  {intl.formatMessage(messages.recommendToAFriend)}
                </Button>
              )}

              <div aria-hidden className={classes.signOut} onClick={handleSignOut}>
                <span>Sign out</span>
              </div>
            </div>
            <div className={classes.horizontalLine} />
            <div className={classes.footerLogo}>
              <img src={TripAdmitFooterMobileIcon} alt="TripAdmit Footer Logo" />
            </div>
          </Grid>
        </Fragment>
      </Modal>

      <InviteGuideModal
        isOpen={isRecommendModalOpen}
        setIsOpen={setIsRecommendModalOpen}
        sendInvitesToGuides={sendInvitesToGuides}
        companyName={user.companyName}
        isRecommendationEmail
      />
    </Toolbar>
  );
}

export default MobileNavBar;
