import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { InfoOutlined } from '@material-ui/icons';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    color: '#000'
  },
  textField: {
    marginTop: 8,
    width: '100%',
    minWidth: '250px',
    '& label.Mui-focused': {
      fontWeight: 600,
      color: '#6b6b6b'
    }
  },
  validationErrorMessage: {
    color: 'red'
  },
  saveButton: {
    color: '#fff',
    width: '100%',
    padding: '8px 24px',
    marginBottom: '16px',
    backgroundColor: '#6997ff',

    '&:hover:not($disabled):not($focused):not($error)': {
      backgroundColor: '#87acff'
    }
  },
  fieldLabel: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'left'
  },
  infoIcon: {
    marginLeft: '5px',
    color: theme.palette.primary.main,
    width: '20px',
    height: '20px'
  },
  inputContainer: {
    marginTop: '10px'
  },
  step: {
    marginTop: '16px'
  },
  buttonContainer: {
    marginTop: '20px'
  },
  stepLabel: {
    fontSize: '14px',
    fontWeight: '500'
  },
  businessStatusContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  businessDetails: {
    alignSelf: 'center',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gap: '10px',
    alignItems: 'flex-start',
    justifyContent: 'center',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    padding: '20px',
    fontSize: '12px',
    borderRadius: '12px',
    width: '90%',
    margin: '16px 0'
  },
  headerText: {
    fontSize: 24,
    color: '#000',
    fontWeight: 'bold'
  },
  details: {
    fontSize: 14,
    color: '#a6a6a6',
    fontWeight: 500
  },
  form: {
    width: '100%'
  },
  radioGroup: {
    marginLeft: '4px'
  }
}));

const businessStatusSchema = yup.object({
  businessStatus: yup.string().required(),
  taxNumber: yup.string()
});

const BusinessStatusField = ({ mergedProfile, handleSubmit, validateSchema }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.container}>
      <div className={classes.headerText}>{intl.formatMessage(messages.businessInformation)}</div>
      <div className={classes.details}>{intl.formatMessage(messages.yourTaxInfo)}</div>
      <div className={classes.businessDetails}>
        <InfoOutlined />
        <span>{intl.formatMessage(messages.businessDetails)}</span>
      </div>

      <div className={classes.businessStatusContainer}>
        <Formik
          initialValues={mergedProfile}
          validateOnChange={false}
          validate={validateSchema(businessStatusSchema)}
          validateOnBlur={false}
          onSubmit={handleSubmit}>
          {props => (
            <Form className={classes.form}>
              <div
                className={classes.step}
                key={intl.formatMessage(messages.selectBusinessType)}
                active={true}>
                <div className={classes.fieldLabel}>
                  <div className={classes.stepLabel}>
                    {intl.formatMessage(messages.selectBusinessType)}
                  </div>
                </div>
                <div>
                  <Typography>
                    <Field name="businessStatus">
                      {({ field, form, meta }) => (
                        <Typography className={classes.inputContainer}>
                          <RadioGroup
                            className={classes.radioGroup}
                            aria-labelledby="businessStatus"
                            name="businessStatus"
                            {...field}>
                            <FormControlLabel
                              value="business"
                              control={<Radio />}
                              label={intl.formatMessage(messages.business)}
                            />
                            <FormControlLabel
                              value="soleTrader"
                              control={<Radio />}
                              label={intl.formatMessage(messages.soleTrader)}
                            />
                            <FormControlLabel
                              value="individual"
                              control={<Radio />}
                              label={intl.formatMessage(messages.individual)}
                            />
                          </RadioGroup>
                        </Typography>
                      )}
                    </Field>
                    {props.errors.businessStatus && (
                      <div className={classes.validationErrorMessage}>
                        {props.errors.businessStatus}
                      </div>
                    )}
                  </Typography>
                </div>
              </div>
              <div
                className={classes.step}
                key={intl.formatMessage(messages.taxNumber)}
                active={true}>
                <div>
                  <Typography>
                    <Field name="taxNumber">
                      {({ field, form, meta }) => (
                        <Typography className={classes.inputContainer}>
                          <TextField
                            className={classes.textField}
                            label={intl.formatMessage(messages.taxNumberLabel)}
                            variant="outlined"
                            type="text"
                            name="taxNumber"
                            {...field}
                          />
                        </Typography>
                      )}
                    </Field>
                    {props.errors.taxNumber && (
                      <div className={classes.validationErrorMessage}>{props.errors.taxNumber}</div>
                    )}
                  </Typography>
                </div>
              </div>
              <Grid item xs={12} className={classes.buttonContainer}>
                <Button type="submit" className={classes.saveButton}>
                  {intl.formatMessage(messages.saveChanges)}
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default BusinessStatusField;
