import { Analytics } from '@analytics';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Grow,
  Link,
  CircularProgress as Loader,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Logo from '../Nav/TopNav/Logo';
import messages from './messages';

export function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://tripadmit.com/">
        TripAdmit
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  errorContainer: {
    marginTop: theme.spacing(1)
  },
  loaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(1)
  },
  forgotPassword: {
    color: '#000',
    fontSize: '12px',
    fontWeight: 600
  },
  forgoPasswordContainer: {
    width: '100%',
    textAlign: 'right',
    marginBottom: '8px'
  },
  signInMessage: {
    color: '#a6a6a6'
  },
  signIn: {
    textAlign: 'center',
    color: '#000000',
    fontWeight: '500'
  }
}));

export default function SignIn({ onSignin, isSigninInProgress, signinError, landingUrl }) {
  const classes = useStyles();
  const intl = useIntl();
  const [state, setState] = useState({
    email: '',
    password: ''
  });

  const onForgotPasswordClick = () => {
    Analytics.track('Forgot password link clicked');
  };

  const updateStateUserName = field => event =>
    setState({ ...state, [field]: event.target.value.toLowerCase() });
  const updateState = field => event => setState({ ...state, [field]: event.target.value });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div style={{ margin: '18px' }}>
          <Logo />
        </div>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={updateStateUserName('email')}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.forgoPasswordContainer}>
                <Link
                  className={classes.forgotPassword}
                  onClick={onForgotPasswordClick}
                  href={`/forgot-password${state.email && `?email=${state.email}`}`}>
                  Forgot password?
                </Link>
              </div>

              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={updateState('password')}
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    //enter pressed
                    e.preventDefault();
                    onSignin(state);
                  }
                }}
              />
            </Grid>
          </Grid>

          {isSigninInProgress && (
            <div className={classes.loaderWrapper}>
              <Loader size={50} color="primary" />
            </div>
          )}
          {signinError && (
            <Grow className={classes.errorContainer} in={Boolean(signinError)}>
              <Alert color="error">{signinError}</Alert>
            </Grow>
          )}

          <Button
            //type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => onSignin(state)}
            className={classes.submit}>
            Sign In
          </Button>
          <Grid container justify="center">
            <Grid item>
              <Link
                className={classes.signInMessage}
                href={`/signup${landingUrl && `?landingUrl=${landingUrl}`}`}
                variant="body2">
                {intl.formatMessage(messages.dontHaveAccount)}
                <span className={classes.signIn}>{intl.formatMessage(messages.signUp)}</span>
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}
