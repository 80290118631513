import { Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { PaymentOptionTypes } from './constants';
import messages from './messages';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: 'auto'
  },
  switcher: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#f6f6f6',
    height: '48px',
    borderRadius: '3px',
    padding: '4px 5px',
    marginBottom: '14px'
  },
  switcherTab: {
    width: '50%',
    border: 'none',
    fontSize: '10px',
    cursor: 'pointer',
    color: '#807c7c',
    fontWeight: '600',
    backgroundColor: '#f6f6f6',
    borderRadius: '3px',
    position: 'relative',
    '&:hover': {
      backgroundColor: '#ffffff'
    }
  },
  switcherTabActive: {
    color: '#000000',
    backgroundColor: '#ffffff'
  },
  textField: {
    maxWidth: '120px',
    fontSize: '13px',
    '& .MuiInputBase-input': {
      height: '40px',
      padding: '5px 14px',
      maxWidth: '120px'
    }
  },
  paymentOptionContainer: { width: '100%' },
  quickTipOptions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '30px',
    gap: '12px'
  },
  defaultValue: {
    marginTop: '30px',
    maxWidth: '100%'
  },
  bespokeInput: {
    width: '100%',
    fontSize: '13px',
    '& .MuiInputBase-input': {
      height: '40px',
      padding: '5px 14px',
      width: '100%'
    }
  },
  saveButton: {
    color: '#fff',
    width: '100%',
    padding: '8px 24px',
    marginBottom: '16px',
    backgroundColor: '#6997ff',
    marginTop: '10px',

    '&:hover:not($disabled):not($focused):not($error)': {
      backgroundColor: '#87acff'
    }
  },
  radioInput: {
    '&label:before': {
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: '0',
      margin: '0',
      appearance: 'none',
      top: '0'
    }
  },
  radioButtonLabel: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    '& input': {
      display: 'block',
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      opacity: '0.01',
      zIndex: '100',
      margin: '0',
      cursor: 'pointer'
    }
  },
  preselectedMessage: {
    fontSize: '10px',
    color: '#000000',
    fontWeight: '600',
    textAlign: 'center',
    marginTop: '5px'
  },
  headerText: {
    fontSize: 24,
    color: '#000',
    fontWeight: 'bold'
  },
  details: {
    fontSize: 14,
    color: '#a6a6a6',
    fontWeight: 500
  },
  error: {
    color: 'red',
    fontSize: '10px',
    marginTop: '5px',
    maxWidth: '120px'
  }
}));

const tipValueSchema = yup.object({
  paymentOptionEnabled: yup.string().required(),
  defaultPaymentAmount: yup.number()
});

const PaymentOptionsSwitcher = ({
  mergedProfile,
  handleSubmit,
  validateSchema,
  asForm = false,
  enableSwitching = true
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(mergedProfile.paymentOptions.paymentOptionEnabled);

  const handleTabSwitch = paymentOption => {
    setActiveTab(paymentOption);
  };

  const handleBlockExponent = evt => evt.key === 'e' && evt.preventDefault();

  const getTabInfo = tab => {
    switch (tab) {
      case PaymentOptionTypes.quick:
        return (
          <div className={classes.quickTipOptions}>
            {Object.entries(mergedProfile.paymentOptions.quickPaymentAmounts).map(([key]) => (
              <Typography key={key}>
                <Field name={`paymentOptions.quickPaymentAmounts.${key}`}>
                  {({ field }) => (
                    <TextField
                      className={classes.textField}
                      label={intl.formatMessage(messages[key])}
                      onKeyDown={handleBlockExponent}
                      variant="outlined"
                      type="number"
                      required
                      InputProps={{
                        inputProps: {
                          min: 2,
                          step: 0.1
                        }
                      }}
                      {...field}
                    />
                  )}
                </Field>
                <Typography className={classes.error}>
                  <ErrorMessage name={`paymentOptions.quickPaymentAmounts.${key}`} />
                </Typography>
              </Typography>
            ))}
          </div>
        );
      case PaymentOptionTypes.bespoke:
        return (
          <div className={classes.defaultValue}>
            <Typography>
              <Field name={'paymentOptions.defaultPaymentAmount'}>
                {({ field }) => (
                  <TextField
                    className={classes.bespokeInput}
                    label={intl.formatMessage(messages.defaultPaymentAmountLabel)}
                    onKeyDown={handleBlockExponent}
                    variant="outlined"
                    type="number"
                    required
                    InputProps={{
                      inputProps: {
                        min: 2,
                        step: 0.1
                      }
                    }}
                    {...field}
                  />
                )}
              </Field>
              <Typography className={classes.error}>
                <ErrorMessage name={`paymentOptions.defaultPaymentAmount`} />
              </Typography>
            </Typography>
          </div>
        );
      default:
        return null;
    }
  };

  const getTabText = tab => {
    switch (tab) {
      case PaymentOptionTypes.quick:
        return {
          header: intl.formatMessage(messages.quickTipAmounts),
          details: intl.formatMessage(messages.letCustomersKnowQuick)
        };
      case PaymentOptionTypes.bespoke:
        return {
          header: intl.formatMessage(messages.bespokeAmount),
          details: intl.formatMessage(messages.letCustomersKnowBespoke)
        };
      default:
        return null;
    }
  };

  const handleShowTab = tab => {
    const tabInfo = getTabInfo(tab);
    const tabText = getTabText(tab);

    if (!tabInfo || !tabText) {
      console.error('Unknown tab type');
      return null;
    }

    return (
      <>
        <div className={classes.textContainer}>
          <div className={classes.headerText}>{tabText.header}</div>
          <div className={classes.details}>{tabText.details}</div>
        </div>
        <div className={classes.paymentOptionContainer}>{tabInfo}</div>

        {tab === PaymentOptionTypes.quick && (
          <div className={classes.preselectedMessage}>
            {intl.formatMessage(messages.preselectedAmount)}
          </div>
        )}
      </>
    );
  };

  const getButtonSwitcher = () => {
    if (!enableSwitching) return;

    return (
      <div className={classes.switcher} role="group">
        <button
          value={PaymentOptionTypes.quick}
          type="button"
          className={`${classes.switcherTab} ${activeTab === PaymentOptionTypes.quick &&
            classes.switcherTabActive}`}
          onClick={() => {
            handleTabSwitch(PaymentOptionTypes.quick);
          }}>
          <label className={classes.radioButtonLabel}>
            <Field
              type="radio"
              name={'paymentOptions.paymentOptionEnabled'}
              value={PaymentOptionTypes.quick}
            />
            {intl.formatMessage(messages.quickTipAmounts)}
          </label>
        </button>
        <button
          value={PaymentOptionTypes.bespoke}
          type="button"
          className={`${classes.switcherTab} ${activeTab === PaymentOptionTypes.bespoke &&
            classes.switcherTabActive}`}
          onClick={() => {
            handleTabSwitch(PaymentOptionTypes.bespoke);
          }}>
          <label className={classes.radioButtonLabel}>
            <Field
              type="radio"
              name={'paymentOptions.paymentOptionEnabled'}
              value={PaymentOptionTypes.bespoke}
            />
            {intl.formatMessage(messages.bespokeAmount)}
          </label>
        </button>
      </div>
    );
  };

  // use component as a part of existing form
  if (!asForm || !handleSubmit) {
    return (
      <div className={classes.container}>
        {getButtonSwitcher()}
        {handleShowTab(activeTab)}
      </div>
    );
  }

  // use component as a standalone form
  return (
    <div className={classes.container}>
      <Formik
        initialValues={mergedProfile}
        validateOnChange={false}
        validate={validateSchema(tipValueSchema)}
        validateOnBlur={false}
        onSubmit={handleSubmit}>
        <Form className={classes.form}>
          {getButtonSwitcher()}
          {handleShowTab(activeTab)}

          <Button type="submit" className={classes.saveButton}>
            {intl.formatMessage(messages.saveChanges)}
          </Button>
        </Form>
      </Formik>
    </div>
  );
};

export default PaymentOptionsSwitcher;
