import { useSelector } from 'react-redux';

export const useIsFeatureForCompanyEnabled = () => {
  const pricingSubscription = useSelector(
    state => state.user.user.companyDetails.pricingSubscription
  );
  const { combinedFeatures, featureOverrides, companyPlan } = pricingSubscription;

  const isFeatureEnabled = feature => {
    // Check if featureOverrides is defined and if the feature is explicitly set
    if (featureOverrides && featureOverrides[feature]) {
      return featureOverrides[feature]; // Return the value directly
    }

    // Check if combinedFeatures is defined and if the feature is not explicitly set
    if (combinedFeatures && combinedFeatures[feature]) {
      return combinedFeatures[feature]; // Return the value directly
    }

    // Otherwise, return false by default
    return companyPlan !== 0;
  };

  return isFeatureEnabled;
};
