/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Analytics } from '@analytics';
import DownArrowIcon from '@assets/images/DownArrow.svg';
import GroupIcon from '@assets/images/GroupIcon.svg';
import UpArrowIcon from '@assets/images/UpArrow.svg';
import { StyleBreakpoints } from '@constants';
import { AccountCircle as AccountCircleIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import GroupDetails from '../GroupDetails';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  container: props => ({
    padding: '17px 20px 18px 26px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.23)',
    borderRadius: '12px',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: '1 0',
    margin: 'auto',
    width: '100%',

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      maxWidth: props.fullSize ? '100%' : '400px',
      width: '100%'
    }
  }),
  headerText: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '8px'
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '20px',
    marginLeft: '-8px'
  },
  title: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#000'
  },
  subTitle: {
    fontSize: '12px',
    fontWeight: '600',
    color: '#a0a0a0'
  },
  icon: {
    display: 'flex',
    width: '40px',
    height: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%'
  },
  avatarArray: {
    display: 'flex',
    flexDirection: 'row'
  },
  avatar: {
    marginLeft: '-8px',
    background: 'white',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.29)'
  },
  plusCircle: {
    backgroundColor: '#dde8ff',
    color: '#5ea5ff',
    fontSize: '18px',
    fontWeight: '600'
  },
  bottomContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  groupDetailsContainer: {
    marginTop: '20px'
  },
  openCloseArrow: {
    display: 'flex',
    alignItems: 'flex-end',

    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

const GroupCard = ({
  user,
  group,
  cardOwnerType,
  cardOwnerElement,
  isUserInGroup,
  defaultOpenState = false,
  setDescription,
  setButtons,
  setDialogIcon,
  setIsConfirmationDialogShown,
  closeConfirmationDialog,
  fullSize = false
}) => {
  const intl = useIntl();
  const classes = useStyles({ fullSize });
  const sortedUsers = useMemo(
    () =>
      group?.users
        ? group.users.sort((a, b) => {
            if (a.profile.avatarUrl) return -1;
            if (b.profile.avatarUrl) return 1;

            return 0;
          })
        : [],
    [group]
  );

  const [shouldShowGroupDetails, setShouldShowGroupDetails] = useState(defaultOpenState);

  const renderAvatars = () => {
    if (!sortedUsers?.length) return;

    return (
      <div className={classes.avatarArray}>
        {sortedUsers.slice(0, 5).map(groupUser => {
          return (
            <div key={groupUser._id} className={classes.element}>
              {groupUser.profile.avatarUrl ? (
                <img
                  className={`${classes.icon} ${classes.avatar}`}
                  src={groupUser.profile.avatarUrl}
                  alt="User avatar"
                />
              ) : (
                <AccountCircleIcon
                  className={`${classes.icon} ${classes.avatar} ${classes.accountIcon}`}
                />
              )}
            </div>
          );
        })}
        {sortedUsers.length > 5 && (
          <div
            className={`${classes.icon} ${classes.plusCircle} ${
              classes.avatar
            }`}>{`+${sortedUsers.length - 5}`}</div>
        )}
      </div>
    );
  };

  return (
    <>
      <div
        className={classes.container}
        onClick={() => {
          Analytics.track(`${shouldShowGroupDetails ? 'close' : 'open'} team details`);
          setShouldShowGroupDetails(!shouldShowGroupDetails);
        }}>
        <div className={classes.topContainer}>
          <div className={classes.icon}>
            <img src={GroupIcon} alt="team icon" />
          </div>
          <div className={classes.headerText}>
            <div className={classes.title}>{intl.formatMessage(messages.team)}</div>
            <div className={classes.subTitle}>{`${intl.formatMessage(
              messages.members
            )} ${sortedUsers.length ?? 0} | ${intl.formatMessage(messages.resources)} ${group
              ?.resources?.length ?? 0}`}</div>
          </div>
        </div>
        <div className={classes.bottomContainer}>
          <div className={classes.groupAvatars}>{renderAvatars()}</div>
          {(sortedUsers.length || group?.resources?.length) && (
            <div className={classes.openCloseArrow}>
              <img
                alt="Toggle team icon"
                src={shouldShowGroupDetails ? UpArrowIcon : DownArrowIcon}
              />
            </div>
          )}
        </div>
      </div>

      {(sortedUsers.length || group?.resources?.length) && shouldShowGroupDetails && (
        <div className={classes.groupDetailsContainer}>
          <GroupDetails
            group={group}
            user={user}
            cardOwnerElement={cardOwnerElement}
            cardOwnerType={cardOwnerType}
            isUserInGroup={isUserInGroup}
            setDialogIcon={setDialogIcon}
            setDescription={setDescription}
            setButtons={setButtons}
            setIsConfirmationDialogShown={setIsConfirmationDialogShown}
            closeConfirmationDialog={closeConfirmationDialog}
            fullSize={fullSize}
          />
        </div>
      )}
    </>
  );
};

export default GroupCard;
