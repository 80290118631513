import { Analytics } from '@analytics';
import ResourceListItem from '@components/TapToTip/ResourceListItem';
import { StyleBreakpoints } from '@constants';
import { makeStyles } from '@material-ui/core/styles';
import QRCode from 'qrcode';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import CustomizableModal from '../../Common/CustomizableModal';
import AccessControlledButton from '../AccessControlledButton';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#000',
    marginBottom: '12px'
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',

    [theme.breakpoints.up(StyleBreakpoints.lg)]: {
      gap: '16px'
    }
  },
  createButton: {
    width: '100%',

    [theme.breakpoints.up(StyleBreakpoints.xs)]: {
      width: 'fit-content'
    }
  },
  contentHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    float: 'right',
    marginBottom: '16px',
    width: '100%',

    [theme.breakpoints.up(StyleBreakpoints.xs)]: {
      flexDirection: 'row',
      alignItems: 'center'
    }
  },
  buttonText: {
    marginRight: '8px'
  }
}));

const ListResources = ({ resources, deleteResource, openCreateResources, openEditResource }) => {
  const intl = useIntl();
  const classes = useStyles();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [resourceToDelete, setResourceToDelete] = useState(null);

  const handleQRDownload = async (url, resourceName) => {
    const data = await QRCode.toDataURL(url).catch(err => {
      console.error(err);
    });

    let a = document.createElement('a');
    a.download = `Tip-Direct-${resourceName || 'Resource'}_QR.png`;
    a.href = data;
    a.click();
  };

  const openDeleteResourceModal = resource => {
    setIsDeleteModalOpen(true);
    setResourceToDelete(resource);
  };

  const handleModalClose = () => {
    setIsDeleteModalOpen(false);
    setResourceToDelete(null);
  };

  const handleResourceRemove = resourceId => {
    deleteResource(resourceId);
    Analytics.track('resource tour clicked');
    handleModalClose();
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.contentHeader}>
          <div className={classes.title}>{intl.formatMessage(messages.title)}</div>

          <AccessControlledButton
            elementStyle={classes.createButton}
            feature={'ResourceTippingPoints'}
            title={intl.formatMessage(messages.createResource)}
            onClick={openCreateResources}
          />
        </div>
        <div className={classes.content}>
          {resources.map(resource => {
            return (
              <ResourceListItem
                resource={resource}
                key={resource._id}
                deleteResource={openDeleteResourceModal}
                editResource={openEditResource}
                downloadQRCode={handleQRDownload}
              />
            );
          })}
        </div>
      </div>
      {isDeleteModalOpen && (
        <CustomizableModal
          isOpen={isDeleteModalOpen}
          onClose={handleModalClose}
          handleHeaderCloseButton={handleModalClose}
          title={intl.formatMessage(messages.confirmAction)}
          body={
            <>
              <p>
                {intl.formatMessage(messages.removeResourceConfirm)}: <b>{resourceToDelete.name}</b>
              </p>

              {resourceToDelete.assignedCardsAmount > 0 && (
                <p>{intl.formatMessage(messages.deleteAssigned)}</p>
              )}
            </>
          }
          handleFooterCloseButton={handleModalClose}
          primaryActionButtonData={{
            content: intl.formatMessage(messages.confirm),
            onClick: () => handleResourceRemove(resourceToDelete._id),
            isDisabled: false
          }}
        />
      )}
    </>
  );
};

export default ListResources;
